import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  let lang = 'PL';
  if (typeof window !== 'undefined') {
    lang = window.localStorage.getItem('lang');
  }
  // prettier-ignore
  return (
    <div className={classes}>
      <iframe src='https://pethomer.com/b/regulamin/' className={css.iframe}></iframe>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
