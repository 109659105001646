import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    initialValues,
    isFromEnquiryForVendor,
  } = props;

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);

  // var bookingData = initialValues && initialValues.bookingData;
  // var isBookingPaid = false;

  // if (!initialValues){
  //   // from vendor view
  //   const transactionId = currentTransaction && currentTransaction.id && currentTransaction.id.uuid
  //   const { profile } = currentTransaction && currentTransaction.customer && currentTransaction.customer.attributes
  //   const { enquiryListing, bookingListing } = profile && profile.publicData || {}
  //   isBookingPaid = bookingListing ? true : false
  //   bookingData = enquiryListing ? enquiryListing.find(item => item.transactionId === transactionId) : null
  // }

  return showFeed ? (
    <div className={classes}>
      <ul className={css.feedHeading}>
        {/* {function(){
          if (bookingData && bookingData.enquiryStatus) {
            if (bookingData.enquiryStatus === 'accepted') {
              return <FormattedMessage id="TransactionPanel.activityPreAccepted" />
            } else {
              return <FormattedMessage id="TransactionPanel.activityHeading" />
            }
          } else {
            return <FormattedMessage id="TransactionPanel.activityHeading" />
          }
        }()} */}
        <li>
          <FormattedMessage id="TransactionPanel.activityHeading" />
        </li>
        <li>
          <FormattedMessage id="TransactionPanel.activityHeading1" />
        </li>
        <li>
          <FormattedMessage id="TransactionPanel.activityHeading2" />
        </li>
        <li>
          <FormattedMessage id="TransactionPanel.activityHeading3" />
          <br/>
          <span className={css.warningInfo}><FormattedMessage id="TransactionPanel.activityHeadingDanger" /></span>
        </li>
      </ul>
      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}
      <ActivityFeed
        className={css.feed}
        messages={messages}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
        onOpenReviewModal={onOpenReviewModal}
        onShowOlderMessages={onShowMoreMessages}
        fetchMessagesInProgress={fetchMessagesInProgress}
      />
    </div>
  ) : null;
};

export default FeedSection;
