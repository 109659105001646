/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import dog from '../src/assets/icon/landing-dog-gray-214.png';
import dog_care from '../src/assets/icon/landing-dog-care-gray-214.png';
import dog_feeding from '../src/assets/icon/landing-dog-food-gray-214.png';
import dog_walking from '../src/assets/icon/landing-walking-dog-gray-214.png';
import dogGreen from '../src/assets/icon/landing-dog-214.png';
import dogGreen_care from '../src/assets/icon/landing-dog-care-214.png';
import dogGreen_feeding from '../src/assets/icon/landing-dog-food-214.png';
import dogGreen_walking from '../src/assets/icon/landing-walking-dog-214.png';

export const filters = [
  {
    id: 'amenities',
    label: 'Service',
    polishLabel: 'Usługa',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      // options: [
      //   { key: 'boarding', label: 'Domowy hotel', icon: dog },
      //   { key: 'walking', label: 'Spacer z psem', icon: dog_walking },
      //   { key: 'feeding', label: 'Szybki karmienie', icon: dog_feeding },
      //   { key: 'daycare', label: 'Opieka dzienna', icon: dog_care },
      // ],

      options: [
        {
          key: 'boarding',
          label: 'Boarding',
          polishLabel: 'Psi hotel (w domu Opiekuna)',
          icon: { default: dog, colored: dogGreen },
        },
        {
          key: 'walking',
          label: 'Dog walking',
          polishLabel: 'Spacer z psem',
          icon: { default: dog_walking, colored: dogGreen_walking },
        },
        {
          key: 'feeding',
          label: 'Drop in visits',
          polishLabel: 'Karmienie (w domu zwierzaka)',
          icon: { default: dog_feeding, colored: dogGreen_feeding },
        },
        {
          key: 'daycare',
          label: 'Dog day care',
          polishLabel: 'Opieka dzienna',
          icon: { default: dog_care, colored: dogGreen_care },
        },
      ],
    },
  },
  {
    id: 'dates',
    label: 'Dates',
    polishLabel: 'Data opieki',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  {
    id: 'keyword',
    label: 'Keyword',
    polishLabel: 'Słowo kluczowe',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  // {
  //   id: 'category',
  //   label: 'Category',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_category'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'smoke', label: 'Smoke' },
  //       { key: 'electric', label: 'Electric' },
  //       { key: 'wood', label: 'Wood' },
  //       { key: 'other', label: 'Other' },
  //     ],
  //   },
  // },
  {
    id: 'timeSlot',
    label: 'Time Slot',
    polishLabel: 'Przedział czasowy',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_timeSlot'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'morning', label: '6am-11am', polishLabel: '6:00 - 11:00' },
        { key: 'afternoon', label: '11am-3pm', polishLabel: '11:00 - 15:00' },
        { key: 'evening', label: '3pm-10pm', polishLabel: '15:00 - 22:00' },
      ],
    },
  },
  {
    id: 'preference',
    label: 'Pet weight',
    polishLabel: 'Wielkość zwierzaka',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_preference'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      options: [
        { key: 'small', label: 'Small: up to 7 kg', polishLabel: 'Mały: do 7 kg' },
        { key: 'medium', label: 'Medium: 8-18 kg', polishLabel: 'Średni: 8-18 kg' },
        { key: 'large', label: 'Large: 19-45 kg', polishLabel: 'Duży: 19-45 kg' },
        { key: 'giant', label: 'Giant: over 45 kg', polishLabel: 'Gigant: ponad 45 kg' },
      ],

      // options: [
      //   { key: 'small', label: 'Mały - do 10 kg' },
      //   { key: 'medium', label: 'Średni - do 10 kg' },
      //   { key: 'large', label: 'duży - do 10 kg' },
      //   { key: 'giant', label: 'Gigant - do 10 kg' },
      // ],
    },
  },
  {
    id: 'benefits',
    label: 'Sitters House',
    polishLabel: 'Dom opiekuna',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_benefits'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      options: [
        { key: 'smoke_free', label: 'Smoking free home', polishLabel: 'Dom bez papierosów' },
        { key: 'house', label: 'Single-family house', polishLabel: 'Dom jednorodzinny' },
        { key: 'garden', label: 'Garden', polishLabel: 'Ogród' },
        { key: 'no_children', label: 'No children at home', polishLabel: 'Dom bez dzieci' },
        { key: 'no_pets', label: 'No other pets at home', polishLabel: 'Dom bez zwierzaków' },
        { key: 'no_cats', label: 'No cats at home', polishLabel: 'Dom bez kota' },
        {
          key: 'own_dog',
          label: 'The sitter has his own dog',
          polishLabel: 'Opiekun ma swojego psa',
        },
        {
          key: 'allowed_on_bed',
          label: 'Pets allowed in the bed',
          polishLabel: 'Zgoda na zwierzęta w łóżku',
        },
        {
          key: 'allowed_on_couch',
          label: 'Pets allowed on the couch',
          polishLabel: 'Zgoda na zwierzęta na kanapie',
        },
      ],
    },
  },
  {
    id: 'skills',
    label: 'Skills',
    polishLabel: 'Umiejętności',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_skills'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      options: [
        {
          key: 'over_3_years',
          label: 'Over 3 years of experience in pet carring',
          polishLabel: 'Ponad 3 lata doświadczenia w opiece nad zwierzętami',
        },
        {
          key: 'care_for_active_dogs',
          label: 'Care for active dogs',
          polishLabel: 'Opieka dla psów aktywnych',
        },
        {
          key: 'care_for_senior_dogs',
          label: 'Care for senior dogs',
          polishLabel: 'Opieka dla psa seniora',
        },
        {
          key: 'care_for_puppies',
          label: 'Care for puppies',
          polishLabel: 'Opieka dla szczeniaka',
        },
        {
          key: 'first_aid',
          label: 'Skilled in first aid',
          polishLabel: 'Umiejętność pierwszej pomocy',
        },
        {
          key: 'administer_medications',
          label: 'Knows how to administer medications',
          polishLabel: 'Umie podawać lekarstwa',
        },
        {
          key: 'make_injections',
          label: 'knows how to make injections',
          polishLabel: 'Umie podawać zastrzyki',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    // { key: 'createdAt', label: 'Newest' },
    // { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price', polishLabel: 'Wg ceny: od najniższej' },
    // { key: 'price', label: 'Highest price' },
    { key: 'pub_ratingScore', label: 'Best Rating', polishLabel: 'Najlepsza ocena' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      polishLabel: 'Wg słów kluczowych',
      longLabel: 'Relevance (Keyword search)',
      polishLongLabel: 'Wg słów kluczowych',
    },
  ],
};
