import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconPinLocation = () => (
  <svg
    className={css.iconSvg}
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 15.1023C5 15.1023 10 8.65913 10 5.89771C10 3.13628 7.76142 0.897705 5 0.897705C2.23858 0.897705 0 3.13628 0 5.89771C0 8.65913 5 15.1023 5 15.1023ZM5 8.17043C6.25519 8.17043 7.27273 7.1529 7.27273 5.89771C7.27273 4.64251 6.25519 3.62498 5 3.62498C3.74481 3.62498 2.72727 4.64251 2.72727 5.89771C2.72727 7.1529 3.74481 8.17043 5 8.17043Z"
      fill="#828282"
    />
  </svg>
);

export default IconPinLocation;
