import React, { Component } from 'react';
import { func, node, shape } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { Page, BookingDateRangeFilter, Button } from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import css from './WpSearchPage.module.css';
import classNames from 'classnames';
import { HeroImageSearchForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import iconCalendar from './icon/icon-calendar-gray.png';
import iconDog from './icon/icon-dog-gray.png';

const filterConfig = config.custom.filters;
const servicesOptions = findOptionsForSelectFilter('amenities', filterConfig);
const preferenceOptions = findOptionsForSelectFilter('preference', filterConfig);

var selectedLang = 'PL';
if (typeof window !== 'undefined') {
  selectedLang = window.localStorage.getItem('lang')
    ? window.localStorage.getItem('lang')
    : selectedLang;
}

class WpSearchPageComponent extends Component {
  constructor(props) {
    super(props);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    selectedService: null,
    selectedPreference: null,
    selectedPreferenceLabel: null,
    selectedDates: null,
    searchQuery: '',
    selectedLocation: null,
    selected: null,
    showDropdown: false,
  };

  render() {
    const { children, className, rootClassName, intl, history, ...pageProps } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterWrapper}>
          <div className={css.column}>
            <div className={css.serviceWrapper}>
              <div
                className={css.cardWrapper}
                onClick={() => this.setState({ selected: servicesOptions[0].key })}
              >
                <div
                  className={
                    this.state.selected === servicesOptions[0].key
                      ? css.cardWrapperSelected
                      : css.card
                  }
                >
                  <img
                    className={css.serviceIcon}
                    src={
                      this.state.selected === servicesOptions[0].key
                        ? servicesOptions[0].icon.colored
                        : servicesOptions[0].icon.default
                    }
                  />
                  <span className={css.serviceLabel}>
                    <FormattedMessage id="WpSearchPage.boarding" />
                  </span>
                </div>
              </div>
              <div
                className={css.cardWrapper}
                onClick={() => this.setState({ selected: servicesOptions[1].key })}
              >
                <div
                  className={
                    this.state.selected === servicesOptions[1].key
                      ? css.cardWrapperSelected
                      : css.card
                  }
                >
                  <img
                    className={css.serviceIcon}
                    src={
                      this.state.selected === servicesOptions[1].key
                        ? servicesOptions[1].icon.colored
                        : servicesOptions[1].icon.default
                    }
                  />{' '}
                  <span className={css.serviceLabel}>
                    <FormattedMessage id="WpSearchPage.walking" />
                  </span>
                </div>
              </div>
            </div>
            <div className={css.dropdownFilterWrapper}>
              <div className={css.dropdownFilter}>
                <HeroImageSearchForm
                  className={css.searchForm}
                  // onSubmit={handleSearchSubmit}
                  onSubmit={locationObj => {
                    const { selectedPlace, search } = locationObj.location;
                    const { ne, sw } = selectedPlace.bounds;
                    const searchQuery = `address=${selectedPlace.address}&bounds=${ne.lat}%2C${ne.lng}%2C${sw.lat}%2C${sw.lng}`;
                    this.setState({ searchQuery: searchQuery });
                  }}
                />
              </div>
              <div className={css.dropdownFilter}>
                <div className={css.children}>
                  <label>
                    <FormattedMessage id="WpSearchPage.petSize" />
                  </label>
                  <div className={css.dropdownFilterGroup}>
                    <div className={css.dropdownFilterIcon}>
                      <img src={iconDog} />
                    </div>
                    <div
                      className={css.preferenceWrapper}
                      onClick={() => {
                        this.setState({ showDropdown: !this.state.showDropdown });
                      }}
                    >
                      <div
                        className={this.state.showDropdown && css.closeWhenClickScreen}
                        onClick={() => this.setState({ showDropdown: false })}
                      ></div>
                      <span className={css.preferenceDisplay}>
                        {this.state.selectedPreferenceLabel
                          ? this.state.selectedPreferenceLabel
                          : intl.formatMessage({ id: 'WpSearchPage.select' })}
                      </span>
                      <ul
                        className={
                          this.state.showDropdown ? css.showDropdown : css.selectPreference
                        }
                      >
                        {preferenceOptions.map(item => (
                          <li
                            key={item.key}
                            value={item.key}
                            className={css.preferenceOptions}
                            style={{
                              color: this.state.selectedPreference == item.key && '#1EAD52',
                            }}
                            onClick={() => {
                              this.setState({
                                selectedPreference: item.key,
                                selectedPreferenceLabel:
                                  selectedLang === 'EN' ? item.label : item.polishLabel,
                                showDropdown: false,
                              });
                            }}
                          >
                            <FormattedMessage id={`WpSearchPage.${item.label}`} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.column}>
            <div className={css.serviceWrapper}>
              <div
                className={css.cardWrapper}
                onClick={() => this.setState({ selected: servicesOptions[2].key })}
              >
                <div
                  className={
                    this.state.selected === servicesOptions[2].key
                      ? css.cardWrapperSelected
                      : css.card
                  }
                >
                  <img
                    className={css.serviceIcon}
                    src={
                      this.state.selected === servicesOptions[2].key
                        ? servicesOptions[2].icon.colored
                        : servicesOptions[2].icon.default
                    }
                  />
                  <span className={css.serviceLabel}>
                    <FormattedMessage id="WpSearchPage.feeding" />
                  </span>
                </div>
              </div>
              <div
                className={css.cardWrapper}
                onClick={() => this.setState({ selected: servicesOptions[3].key })}
              >
                <div
                  className={
                    this.state.selected === servicesOptions[3].key
                      ? css.cardWrapperSelected
                      : css.card
                  }
                >
                  <img
                    className={css.serviceIcon}
                    src={
                      this.state.selected === servicesOptions[3].key
                        ? servicesOptions[3].icon.colored
                        : servicesOptions[3].icon.default
                    }
                  />
                  <span className={css.serviceLabel}>
                    <FormattedMessage id="WpSearchPage.dayCare" />
                  </span>
                </div>
              </div>
            </div>
            <div className={css.dropdownFilterWrapper}>
              <div className={css.dropdownFilter}>
                <div className={css.children}>
                  <label>
                    <FormattedMessage id="WpSearchPage.date" />
                  </label>
                  <div className={css.dropdownFilterGroup}>
                    <div className={css.dropdownFilterIcon}>
                      <img src={iconCalendar} />
                    </div>
                    <BookingDateRangeFilter
                      id="WpSearchPageBookingDate"
                      label={intl.formatMessage({ id: 'WpSearchPage.datePlaceholder' })}
                      currentPage="LandingPage"
                      minimumNights={this.state.selected === 'boarding' ? 1 : 0}
                      // queryParamNames={queryParamNames}
                      // initialValues={initialValues(queryParamNames)}
                      // onSubmit={this.getHandleChangedValueFn}
                      initialValues={this.state.selectedDates}
                      onSubmit={dates => this.setState({ selectedDates: dates })}
                      className={css.dateSelector}
                      // {...config}
                      // {...rest}
                    />
                  </div>
                </div>
              </div>
              <div className={css.searchButtonWrapper}>
                <Button
                  className={css.primaryButton}
                  // onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
                  onClick={() => {
                    const sDate = `&dates=${
                      this.state.selectedDates ? this.state.selectedDates.dates : ''
                    }`;
                    const sAmenities = `&pub_amenities=has_all%3A${this.state.selected}`;
                    const sPreference = `&pub_preference=has_all%3A${this.state.selectedPreference}`;
                    const sLocation = this.state.searchQuery
                      ? this.state.searchQuery
                      : `address=${
                          selectedLang === 'EN' ? 'Poland' : 'Polska'
                        }&bounds=54.8932281999438%2C24.1455979034865%2C49.0020460154192%2C14.1229290098701`;
                    const sortBy = '&sort=pub_ratingScore';
                    var sQuery = sLocation;
                    if (this.state.selectedDates) {
                      sQuery = sQuery + sDate;
                    }
                    if (this.state.selected) {
                      sQuery = sQuery + sAmenities;
                    }
                    if (this.state.selectedPreference) {
                      sQuery = sQuery + sPreference;
                    }
                    history.push(`/s?${sQuery}${sortBy}`);
                  }}
                >
                  <FormattedMessage id="WpSearchPage.searchButton" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={css.columnMobile}>
          <div className={css.serviceWrapper}>
            <div
              className={css.cardWrapper}
              onClick={() => this.setState({ selected: servicesOptions[0].key })}
            >
              <div
                className={
                  this.state.selected === servicesOptions[0].key
                    ? css.cardWrapperSelected
                    : css.card
                }
              >
                <img
                  className={css.serviceIcon}
                  src={
                    this.state.selected === servicesOptions[0].key
                      ? servicesOptions[0].icon.colored
                      : servicesOptions[0].icon.default
                  }
                />
                <span className={css.serviceLabel}>
                  <FormattedMessage id="WpSearchPage.boarding" />
                </span>
              </div>
            </div>
            <div
              className={css.cardWrapper}
              onClick={() => this.setState({ selected: servicesOptions[1].key })}
            >
              <div
                className={
                  this.state.selected === servicesOptions[1].key
                    ? css.cardWrapperSelected
                    : css.card
                }
              >
                <img
                  className={css.serviceIcon}
                  src={
                    this.state.selected === servicesOptions[1].key
                      ? servicesOptions[1].icon.colored
                      : servicesOptions[1].icon.default
                  }
                />{' '}
                <span className={css.serviceLabel}>
                  <FormattedMessage id="WpSearchPage.walking" />
                </span>
              </div>
            </div>
          </div>
          <div className={css.serviceWrapper}>
            <div
              className={css.cardWrapper}
              onClick={() => this.setState({ selected: servicesOptions[2].key })}
            >
              <div
                className={
                  this.state.selected === servicesOptions[2].key
                    ? css.cardWrapperSelected
                    : css.card
                }
              >
                <img
                  className={css.serviceIcon}
                  src={
                    this.state.selected === servicesOptions[2].key
                      ? servicesOptions[2].icon.colored
                      : servicesOptions[2].icon.default
                  }
                />
                <span className={css.serviceLabel}>
                  <FormattedMessage id="WpSearchPage.feeding" />
                </span>
              </div>
            </div>
            <div
              className={css.cardWrapper}
              onClick={() => this.setState({ selected: servicesOptions[3].key })}
            >
              <div
                className={
                  this.state.selected === servicesOptions[3].key
                    ? css.cardWrapperSelected
                    : css.card
                }
              >
                <img
                  className={css.serviceIcon}
                  src={
                    this.state.selected === servicesOptions[3].key
                      ? servicesOptions[3].icon.colored
                      : servicesOptions[3].icon.default
                  }
                />
                <span className={css.serviceLabel}>
                  <FormattedMessage id="WpSearchPage.dayCare" />
                </span>
              </div>
            </div>
          </div>
          <div className={css.dropdownFilterWrapper}>
            <div className={css.dropdownFilter}>
              <HeroImageSearchForm
                className={css.searchForm}
                // onSubmit={handleSearchSubmit}
                onSubmit={locationObj => {
                  const { selectedPlace } = locationObj.location;
                  const { ne, sw } = selectedPlace.bounds;
                  const searchQuery = `address=${selectedPlace.address}&bounds=${ne.lat}%2C${ne.lng}%2C${sw.lat}%2C${sw.lng}`;
                  this.setState({ searchQuery: searchQuery });
                }}
              />
            </div>
            <div className={css.dropdownFilter}>
              <div className={css.children}>
                <label>
                  <FormattedMessage id="WpSearchPage.petSize" />
                </label>
                <div className={css.dropdownFilterGroup}>
                  <div className={css.dropdownFilterIcon}>
                    <img src={iconDog} />
                  </div>
                  <div
                    className={css.preferenceWrapper}
                    onClick={() => {
                      this.setState({ showDropdown: !this.state.showDropdown });
                    }}
                  >
                    <div
                      className={this.state.showDropdown && css.closeWhenClickScreen}
                      onClick={() => this.setState({ showDropdown: false })}
                    ></div>
                    <span className={css.preferenceDisplay}>
                      {this.state.selectedPreferenceLabel
                        ? this.state.selectedPreferenceLabel
                        : intl.formatMessage({ id: 'WpSearchPage.select' })}
                    </span>
                    <ul
                      className={this.state.showDropdown ? css.showDropdown : css.selectPreference}
                    >
                      {preferenceOptions.map(item => (
                        <li
                          key={item.key}
                          value={item.key}
                          className={css.preferenceOptions}
                          style={{
                            color: this.state.selectedPreference == item.key && '#1EAD52',
                          }}
                          onClick={() => {
                            this.setState({
                              selectedPreference: item.key,
                              selectedPreferenceLabel:
                                selectedLang === 'EN' ? item.label : item.polishLabel,
                              showDropdown: false,
                            });
                          }}
                        >
                          <FormattedMessage id={`WpSearchPage.${item.label}`} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.dropdownFilterWrapper}>
            <div className={css.dropdownFilter}>
              <div className={css.children}>
                <label>
                  <FormattedMessage id="WpSearchPage.date" />
                </label>
                <div className={css.dropdownFilterGroup}>
                  <div className={css.dropdownFilterIcon}>
                    <img src={iconCalendar} />
                  </div>
                  <BookingDateRangeFilter
                    id="WpSearchPageBookingDate"
                    label={intl.formatMessage({ id: 'WpSearchPage.datePlaceholder' })}
                    currentPage="LandingPage"
                    // queryParamNames={queryParamNames}
                    // initialValues={initialValues(queryParamNames)}
                    // onSubmit={this.getHandleChangedValueFn}
                    initialValues={this.state.selectedDates}
                    onSubmit={dates => this.setState({ selectedDates: dates })}
                    className={css.dateSelector}
                    // {...config}
                    // {...rest}
                  />
                </div>
              </div>
            </div>
            <div className={css.searchButtonWrapper}>
              <Button
                className={css.primaryButton}
                // onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
                onClick={() => {
                  const sDate = `&dates=${
                    this.state.selectedDates ? this.state.selectedDates.dates : ''
                  }`;
                  const sAmenities = `&pub_amenities=has_all%3A${this.state.selected}`;
                  const sPreference = `&pub_preference=has_all%3A${this.state.selectedPreference}`;
                  const sLocation = this.state.searchQuery
                    ? this.state.searchQuery
                    : `address=${
                        selectedLang === 'EN' ? 'Poland' : 'Polska'
                      }&bounds=54.8932281999438%2C24.1455979034865%2C49.0020460154192%2C14.1229290098701`;
                  var sQuery = sLocation;
                  const sortBy = '&sort=pub_ratingScore';
                  if (this.state.selectedDates) {
                    sQuery = sQuery + sDate;
                  }
                  if (this.state.selected) {
                    sQuery = sQuery + sAmenities;
                  }
                  if (this.state.selectedPreference) {
                    sQuery = sQuery + sPreference;
                  }
                  history.push(`/s?${sQuery}${sortBy}`);
                }}
              >
                <FormattedMessage id="WpSearchPage.searchButton" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WpSearchPageComponent.defaultProps = {
  children: null,
};

WpSearchPageComponent.propTypes = {
  children: node,
  intl: intlShape.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const WpSearchPage = compose(
  withRouter,
  injectIntl
)(WpSearchPageComponent);

// const WpSearchPage = connect(mapStateToProps)(WpSearchPageComponent);

WpSearchPage.displayName = 'WpSearchPage';

export default WpSearchPage;
