/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useState } from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemUnitsMaybe from './LineItemUnitsMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';

import css from './BookingBreakdown.module.css';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

export const BookingBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    currentUser,
    bookingServiceType,
    isFromEnquiryForVendor,
    bookingDataFromEnquiry,
  } = props;

  const [viewDetails, setViewDetail] = useState(false);

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  // NOTES::
  // profilePublicData only comes from TransactionPanel on the order details page
  // if transaction.customer is not empty means it is viewed by vendor(where the publicData from currentUser appeared empty)
  var profilePublicData = null;
  const seg1 = window.location.pathname.split('/')[1];

  if (seg1 === 'sale') {
    profilePublicData =
      transaction &&
      transaction.customer &&
      transaction.customer.attributes &&
      transaction.customer.attributes.profile &&
      transaction.customer.attributes.profile.publicData;
  } else {
    profilePublicData =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData;
  }

  const checkoutPageData = JSON.parse(window.sessionStorage.getItem('CheckoutPage'));
  var bookingData = checkoutPageData && checkoutPageData.bookingData;

  if (profilePublicData) {
    // if the page is the order details, replace the bookingData with the saved one
    bookingData =
      profilePublicData.bookingListing &&
      profilePublicData.bookingListing.find(x => x.orderId === transaction.id.uuid);
  } else if (isFromEnquiryForVendor) {
    if (bookingDataFromEnquiry) {
      bookingData = {
        ...bookingDataFromEnquiry,
        bookingServiceType: bookingDataFromEnquiry.serviceType,
      };
    }
  }

  const filterConfig = config.custom.filters;
  const timeSlotOptions = findOptionsForSelectFilter('timeSlot', filterConfig);
  const servicesOptions = findOptionsForSelectFilter('amenities', filterConfig);
  const preferenceOptions = findOptionsForSelectFilter('preference', filterConfig);

  const timeSlot =
    bookingData &&
    bookingData.bookingTimeSlot &&
    timeSlotOptions.filter(o => {
      if (Array.isArray(bookingData.bookingTimeSlot)) {
        return bookingData.bookingTimeSlot.includes(o.key)
      } 
      return bookingData.bookingTimeSlot === o.key
    });

  const services =
    bookingData &&
    bookingData.bookingServiceType &&
    servicesOptions.find(o => bookingData.bookingServiceType === o.key);

  const preference =
    bookingData &&
    bookingData.bookingPreference &&
    preferenceOptions.find(o => bookingData.bookingPreference === o.label);

  var selectedLang = 'PL';
  if (typeof window !== 'undefined') {
    selectedLang = window.localStorage.getItem('lang')
      ? window.localStorage.getItem('lang')
      : selectedLang;
  }

  return (
    <div className={classes}>
      <LineItemBookingPeriod booking={booking} unitType={unitType} dateType={dateType} service={bookingServiceType}/>
      <LineItemUnitsMaybe transaction={transaction} unitType={unitType} />
      <LineItemBasePriceMaybe
        transaction={transaction}
        unitType={unitType}
        intl={intl}
        bookingServiceType={bookingServiceType}
      />
      {services ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.services" />
          </span>
          <span className={css.itemValue}>
            {selectedLang === 'EN' ? services.label : services.polishLabel}
          </span>
        </div>
      ) : null}

      {preference ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.petSize" />
          </span>
          <span className={css.itemValue}>
            {selectedLang === 'EN' ? preference.label : preference.polishLabel}
          </span>
        </div>
      ) : null}

      {timeSlot && timeSlot.length ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.timeSlot" />
          </span>
          <span className={css.itemValue}>
            {
              timeSlot.map(t => <span>{selectedLang === 'EN' ? t.label : t.polishLabel}<br /></span>)
            }
          </span>
        </div>
      ) : null}

      {isProvider && !isFromEnquiryForVendor ? (
        viewDetails ? (
          <div className="">
            <LineItemUnknownItemsMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            <LineItemSubTotalMaybe
              transaction={transaction}
              unitType={unitType}
              userRole={userRole}
              intl={intl}
            />
            <LineItemRefundMaybe transaction={transaction} intl={intl} />

            <LineItemCustomerCommissionMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />
            <LineItemCustomerCommissionRefundMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />

            <LineItemProviderCommissionMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            <LineItemProviderCommissionRefundMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            <div className={css.expandDetails} onClick={() => setViewDetail(false)}>
              <FormattedMessage id="BookingBreakdown.viewLess" />
            </div>
          </div>
        ) : (
          <div className={css.expandDetails} onClick={() => setViewDetail(true)}>
            <FormattedMessage id="BookingBreakdown.viewMore" />
          </div>
        )
      ) : null}

      <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} />

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = { rootClassName: null, className: null, dateType: null };

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
