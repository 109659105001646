import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

var selectedLang = 'PL';
if (typeof window !== 'undefined') {
  selectedLang = window.localStorage.getItem('lang')
    ? window.localStorage.getItem('lang')
    : selectedLang;
}

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.

// Warsaw, Masovian Voivodeship, Poland&bounds=52.368154,21.271150979,52.097850137,20.851689&origin=52.21667,21.03333
const defaultLocations = [
  {
    id: 'default-warsaw',
    predictionPlace: {
      address:
        selectedLang === 'EN'
          ? 'Warsaw, Masovian Voivodeship, Poland'
          : 'Warszawa, województwo mazowieckie, Polska',
      bounds: new LatLngBounds(
        new LatLng(52.368154, 21.271150979),
        new LatLng(52.097850137, 20.851689)
      ),
    },
  },
  {
    id: 'default-krakow',
    predictionPlace: {
      address:
        selectedLang === 'EN'
          ? 'Kraków, Lesser Poland Voivodeship, Poland'
          : 'Kraków, województwo małopolskie, Polska',
      bounds: new LatLngBounds(
        new LatLng(50.126131856, 20.21734565),
        new LatLng(49.967709, 19.792238255)
      ),
    },
  },
];
export default defaultLocations;
