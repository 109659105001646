import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9573 0H4.04259C1.80997 0 0 1.8099 0 4.04259V25.9575C0 28.1902 1.8099 30.0001 4.04259 30.0001H14.8508L14.8693 19.2797H12.0841C11.7222 19.2797 11.4284 18.987 11.427 18.625L11.4136 15.1694C11.4122 14.8055 11.7069 14.5097 12.0708 14.5097H14.8509V11.1707C14.8509 7.29582 17.2175 5.1859 20.6741 5.1859H23.5106C23.8735 5.1859 24.1678 5.48012 24.1678 5.8431V8.7569C24.1678 9.11973 23.8737 9.41387 23.5109 9.41409L21.7702 9.4149C19.8904 9.4149 19.5264 10.3082 19.5264 11.6191V14.5098H23.6571C24.0506 14.5098 24.356 14.8535 24.3096 15.2443L23.9 18.7C23.8609 19.0307 23.5804 19.2798 23.2475 19.2798H19.5448L19.5264 30.0002H25.9575C28.1901 30.0002 30 28.1903 30 25.9577V4.04259C29.9999 1.8099 28.19 0 25.9573 0Z"
        fill="#1EAD52"
      />
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
